import React, { useState } from 'react'
import loadable from '@loadable/component'
import PropTypes from 'prop-types'

const PaginationNavigation = loadable(() =>
    import('/src/components/Search/PaginationNavigation')
)
const SearchResultsDisplay = loadable(() =>
    import('/src/components/Search/SearchResults/SearchResultsDisplay')
)

const SearchResultsPostList = ({
    type,
    postTypeCategoryKey,
    postsPerPage,
    searchTerm,
    itemsRepeater
}) => {
    const [isCurrentPage, setCurrentPage] = useState(1)
    const [searchResultsInDom, setSearchResultsInDom] = useState(false)

    let paginatedPages = []
    let pagination = []

    for (let index = 0; index < itemsRepeater.length; index += postsPerPage) {
        paginatedPages.push(itemsRepeater.slice(index, index + postsPerPage))
        pagination.push(index / postsPerPage + 1)
    }

    return paginatedPages.map((node, index) => {
        const pageNumber = index + 1
        const firstItem = index * postsPerPage + 1
        const lastItem =
            paginatedPages.length === pageNumber
                ? itemsRepeater.length
                : firstItem + (postsPerPage - 1) // If on the last paginated page, set the last item to the length of the items list, otherwise set it to the last item from that set of results

        if (isCurrentPage === pageNumber) {
            return (
                <div key={index}>
                    {searchResultsInDom && firstItem === lastItem ? (
                        <div>
                            Showing result <strong>{lastItem}</strong> of{' '}
                            <strong>{itemsRepeater.length}</strong>
                        </div>
                    ) : searchResultsInDom ? (
                        <div>
                            Showing results{' '}
                            <strong>
                                {firstItem} - {lastItem}
                            </strong>{' '}
                            of <strong>{itemsRepeater.length}</strong>
                        </div>
                    ) : (
                        <div>Loading...</div>
                    )}

                    <SearchResultsDisplay
                        type={type}
                        postTypeCategoryKey={postTypeCategoryKey}
                        searchTerm={searchTerm}
                        data={node}
                        setSearchResultsInDom={setSearchResultsInDom}
                    />

                    {paginatedPages.length > 1 && (
                        <PaginationNavigation
                            pagination={pagination}
                            pageNumber={pageNumber}
                            setCurrentPage={setCurrentPage}
                            isCurrentPage={isCurrentPage}
                        />
                    )}
                </div>
            )
        } else return null
    })
}

SearchResultsPostList.propTypes = {
    type: PropTypes.oneOf(['search', 'category']).isRequired,
    postTypeCategoryKey: PropTypes.string,
    postsPerPage: PropTypes.number,
    searchTerm: PropTypes.string,
    itemsRepeater: PropTypes.array.isRequired
}

SearchResultsPostList.defaultProps = {
    type: 'search',
    postsPerPage: 6,
    itemsRepeater: []
}

export default SearchResultsPostList
